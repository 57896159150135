import { ErrorEvent } from '@sentry/types';
import { isNonEmptyString, matchStrings } from '@sortlist-frontend/utils';

import { IGNORED_CLIENT_ERRORS } from './sentry.client';

export type EnhancedEvent = ErrorEvent & {
  title?: string;
  metadata?: {
    type?: string;
    value?: string;
    filename?: string;
  };
  errors?: {
    url?: string;
  }[];
  location?: string;
};

const IGNORED_ERRORS_FOR_SPECIFIC_BROWSERS: IgnoredBrowserError[] = [
  // Ignore errors from Webview and TikTok browsers
  { affectedBrowsers: [/Webview/i, /Tiktok/i] },
  {
    errorMessage: "NotFoundError: Failed to execute 'removeChild' on 'Node'",
    affectedBrowsers: ['Edge', 'Opera'],
  },
  {
    errorMessage: 'private fields are not currently supported',
    affectedBrowsers: ['Firefox'],
  },
  // Google maps safari errors
  { errorTrace: /.+(maps-api-v3|maps\/api\/js).+/, affectedBrowsers: ['Safari', 'Mobile Safari'] },
];

export function isIgnoredError(error: string) {
  return IGNORED_CLIENT_ERRORS.some((ignoredError) => matchStrings(ignoredError, error));
}

export function isIgnoredEvent(event: EnhancedEvent): boolean {
  return (
    IGNORED_CLIENT_ERRORS.some((ignoredError) => matchEventToErrorMessage(event, ignoredError)) ||
    shouldIgnoreErrorFromBrowsers(event)
  );
}

const isErrorFromBrowser = (event: EnhancedEvent, affectedBrowsers: (string | RegExp)[]) => {
  const eventBrowserName = event.contexts?.browser?.name;
  if (!isNonEmptyString(eventBrowserName)) return false;

  return affectedBrowsers.some((browser) => {
    return typeof browser === 'string' ? eventBrowserName.includes(browser) : browser.test(eventBrowserName);
  });
};

type IgnoredBrowserError = {
  errorMessage?: string;
  errorTrace?: RegExp;
  affectedBrowsers: (RegExp | string)[];
};

function shouldIgnoreErrorFromBrowsers(event: EnhancedEvent): boolean {
  return IGNORED_ERRORS_FOR_SPECIFIC_BROWSERS.some((ignoredError) => {
    const { errorTrace, errorMessage, affectedBrowsers } = ignoredError;

    const isBrowserMatch = isErrorFromBrowser(event, affectedBrowsers);

    if (!isBrowserMatch) return false;

    if (errorMessage == null && affectedBrowsers == null) return true;

    return (
      (isNonEmptyString(errorMessage) && matchEventToErrorMessage(event, errorMessage)) ||
      (isNonEmptyString(errorTrace) && matchEventToStackTrace(event, errorTrace))
    );
  });
}

function matchEventToStackTrace(event: EnhancedEvent, trace: string | RegExp): boolean {
  const eventTraces: string[] = (event.errors?.map((error) => error.url).filter((x) => x != null) ??
    event.exception?.values?.[0]?.stacktrace?.frames?.map((frame) => frame.filename).filter((x) => x != null) ??
    []) as string[];

  const eventTrace = event.location ?? event.metadata?.filename;

  return (
    (isNonEmptyString(eventTrace) && matchStrings(eventTrace, trace)) ||
    eventTraces.some((eventTrace) => matchStrings(eventTrace, trace))
  );
}

function matchEventToErrorMessage(event: EnhancedEvent, error: string | RegExp): boolean {
  const { title, metadata, message, exception } = event;
  // type is often the title of the error, and value the message. So we should NOT check only the type
  const { value } = metadata ?? {};
  // We assume the first entry is the most relevant
  const errorFromException = exception?.values?.[0].value;

  return (
    (isNonEmptyString(title) && matchStrings(title, error)) ||
    (isNonEmptyString(value) && matchStrings(value, error)) ||
    (isNonEmptyString(message) && matchStrings(message, error)) ||
    (isNonEmptyString(errorFromException) && matchStrings(errorFromException, error))
  );
}
